import {fadeIn, fadeOut} from './fadeJs'

ScrollReveal({
    reset: false,
    distance: '20px',
    viewFactor: 0.7,
    duration: 600,
})

ScrollReveal().reveal('.sr-el')
ScrollReveal().reveal('.sr-el-1', {delay: 500})
ScrollReveal().reveal('.sr-el-2', {delay: 1000})
ScrollReveal().reveal('.sr-el-3', {delay: 1500})
ScrollReveal().reveal('.sr-el-35', {delay: 1700})
ScrollReveal().reveal('.sr-el-4', {delay: 2000})

ScrollReveal().reveal('.sr-reset', {reset: true})

// Service Items
const serviceButtons = document.querySelectorAll('.service-btn')
const serviceItems = document.querySelectorAll('.service-items')

const closeItem = () => {
    serviceButtons.forEach(el1 => {
        el1.classList.remove('active')
    })
    serviceItems.forEach(el1 => {
        el1.removeAttribute('style')
    })
}

serviceButtons.forEach(el => {
    el.addEventListener('click', ev => {
        ev.preventDefault()
        if(el.classList.contains('active')) {
            closeItem()
        } else {
            closeItem()
            el.classList.add('active')
            const serviceItem = document.querySelector(el.dataset.target)
            fadeIn(serviceItem)
        }
    })
})
